<template>
  <div class="_profile-details">
    <div class="w-12/12">

      <div class="grid justify-items-center grid-cols-1 bg-grey bg-opacity-25 rounded-t-lg p-5">
        <div>
          <div class="overlay-container z-20"
            @mouseenter="uploadOverlay = true"
            @mouseleave="uploadOverlay = false"
            @click="avatarEditor.visible = true"
          >
            <feather-icon v-if="uploadOverlay" class="overlayed flex-shrink z-10 my-0 inset-0 cursor-pointer bg-contain rounded-full bg-black bg-opacity-75" icon="CameraIcon" svgClasses="mx-auto my-0 w-10 h-10 text-primary opacity-75"/>
            <vs-avatar
            :key="key"
            :src="myProfilePicture"
            class="border-white border-5 m-0 border-solid ring-inset"
            size="100px"
            />
          </div>
        </div>

        <div class="grid grid-rows-2">
          <span class="text-lg text-center font-semibold">{{`${user.name} ${user.surname}`}}</span>
          <span class="text-sm sm:text-md text-center">{{user.email}}</span>
        </div>
      </div>

      <vx-card class="relative text-base leading-loose mb-5">

        <h4 class="mx-auto text-center lg:text-left mb-10 lg:mb-5"> {{ $t('dados-usuario') }} </h4>
        <vs-row class="w-full gap-2">
          <vs-input v-model="user.name" class="flex-grow" :label="$t('name')"/>
          <vs-input v-model="user.surname" class="flex-grow" :label="$t('sobrenome')"/>
        </vs-row>

        <vs-row class="mb-2 gap-2">
          <div class="flex-grow">
            <vs-select :label="$t('genero')" v-model="user.gender" class="w-full hover:text-primary">
              <vs-select-item
              value="female"
              :text="$t('female')" />
              <vs-select-item
              value="male"
              :text="$t('male')" />
              <vs-select-item
              value="undisclosed"
              :text="$t('undisclosed')" />
            </vs-select>
            <span
              class="text-danger text-sm"
              v-show="errors.has('gender')"
            >
              {{ errors.first('gender') }}
            </span>
          </div>
          <div class="flex-grow">
            <label class="mr-2 align-middle vs-select--label inline-block">{{ $t('data-de-nascimento') }}</label>
            <datetime
              v-model="user.birthdate"
              input-class="vs-inputx vs-input--input normal text-base font-semibold hover:text-primary cursor-pointer"
              type="date"
              format="dd/MM/yyyy"
              value-zone="America/Sao_Paulo"
              use24-hour
            >
            </datetime>
            <span
              class="text-danger text-sm"
              v-show="errors.has('birthdate')"
            >
              {{ errors.first('birthdate') }}
            </span>
          </div>
          <div class="flex-grow">
            <form>
            <vs-input
              autocomplete="off"
              name="user_phone"
              class="w-full"
              label="Telefone/Celular"
              v-validate="'max:20|numeric'"
              data-vv-as="Telefone/Celular"
              data-vv-validate-on="input"
              v-model="user.phone"/>
            </form>
            <span
              class="text-danger text-sm"
              v-show="errors.has('phone')"
            >
              {{ errors.first('phone') }}
            </span>
          </div>
        </vs-row>

        <vs-row>
          <label class="w-full">{{ $t('cadastrado-desde') }}</label>
          <datetime
            v-model="since"
            input-class="vs-inputx vs-input--input normal border-none text-base font-semibold"
            type="date"
            format="dd/MM/yyyy"
            value-zone="America/Sao_Paulo"
            use24-hour
            disabled
          >
          </datetime>
        </vs-row>
        <vs-row>
          <vs-col>
            <vs-button
              class="float-right"
              @click="updateProfile"
              >{{ $t('action.save') }}</vs-button
            >
          </vs-col>
        </vs-row>
      </vx-card>
      <vs-popup
        :title="$t('foto-perfil')"
        :active.sync="avatarEditor.visible"
        v-tooltip.left="{
          content: 'Clique no editor ou arraste um arquivo para alterar a imagem.',
          delay: { show: 500, hide: 100 },
          classes: 'tooltipZFix'
        }"
        v-esc="closeAvatarPhoto"
      >
        <vs-row class="grid place-items-center">
          <avatar-editor
              :width="200"
              :height="200"
              :scale = "avatarEditor.scale"
              :rotation = "avatarEditor.rotation"
              :borderRadius = "200"
              ref="avatareditor"
              @vue-avatar-editor:image-ready="onImageReady"
              class="border-1 border-primary border-double vs-col place-self-center"
              style="height: 256px;"
              :image="myProfilePicture"
          ></avatar-editor>
          <div class="EditorButtons mt-2 flex justify-between w-1/3 space-x-3">
            <feather-icon
              @mousedown="doZoom(1)"
              @mouseup="doZoom(0)"
              @click="tinyZoom(1)"
              icon="ZoomInIcon"
              :svgClasses="aditionalStyleWhen(zoomingIn, 'w-8 h-8 lg:w-5 lg:h-5 hover:primary', 'primary')"
              v-tooltip.auto="{
                content: 'Ampliar',
                delay: { show: 500, hide: 100 },
                classes: 'tooltipZFix'
              }"
            />
            <feather-icon
              @mousedown="doZoom(-1)"
              @mouseup="doZoom(0)"
              @click="tinyZoom(-1)"
              icon="ZoomOutIcon"
              :svgClasses="aditionalStyleWhen(zoomingOut, 'w-8 h-8 lg:w-5 lg:h-5 hover:primary', 'primary')"
              v-tooltip.auto="{
                content: 'Reduzir',
                delay: { show: 500, hide: 100 },
                classes: 'tooltipZFix'
              }"
            />
            <feather-icon
              @click="doRotate(1, 45)"
              icon="RotateCwIcon"
              :svgClasses="aditionalStyleWhen(rotatingCW, 'w-8 h-8 lg:w-5 lg:h-5 hover:primary', 'primary')"
              v-tooltip.auto="{
                content: 'Girar sentido horário',
                delay: { show: 500, hide: 100 },
                classes: 'tooltipZFix'
              }"
            />
            <feather-icon
              @click="doRotate(-1, 45)" icon="RotateCcwIcon"
              :svgClasses="aditionalStyleWhen(rotatingCCW, 'w-8 h-8 lg:w-5 lg:h-5 hover:primary', 'primary')"
              v-tooltip.auto="{
                content: 'Girar sentido anti-horário',
                delay: { show: 500, hide: 100 },
                classes: 'tooltipZFix'
              }"
            />
            <feather-icon
              @click="clearEdits"
              icon="XIcon"
              :svgClasses="aditionalStyleWhen(rotatingCCW, 'w-8 h-8 lg:w-5 lg:h-5 hover:primary', 'primary')"
              v-tooltip.auto="{
                content: 'Limpar',
                delay: { show: 500, hide: 100 },
                classes: 'tooltipZFix'
              }"
            />
          </div>
        </vs-row>
        <vx-row>
          <vx-col>
            <vs-button
              :disabled="!validDetails"
              class="float-right mr-2"
              @click="updateProfilePicture"
              >{{ $t('action.save') }}</vs-button
            >
          </vx-col>
        </vx-row>
      </vs-popup>

    </div>
  </div>
</template>

<script>
import LoginService from '@/services/api/LoginService'
import { Datetime } from 'vue-datetime'
import { VueAvatar } from 'vue-avatar-editor-improved'
import 'vue-datetime/dist/vue-datetime.css'
import { isEmpty } from '@/acl/util'
import { validate } from 'vee-validate'

export default {
  components: {
    datetime: Datetime,
    AvatarEditor: VueAvatar
  },
  data: () => ({
    loginService : null,
    uploadOverlay: false,
    user : {
      gender : null,
      name: null,
      surname: null,
      birthdate: null,
      email: null,
      phone: ''
    },
    since: null,
    avatarEditor : {
      overlay: false,
      visible: false,
      rotation: 0,
      rotationCounter: 0,
      scale: 1,
      zoom: {on: false, direction: 0, locked: false},
      rotate: {on: false, direction: 0, degree: 45, locked: false},
      actionTimer: null,
      actionTimegate: null
    },
    key: 0
  }),
  methods: {
    closeAvatarPhoto() {
      this.avatarEditor.visible = false
    },
    onImageReady: function onImageReady() {
      this.scale = 1
      this.rotation = 0
    },
    updateProfile() {
      const name = `${this.user.name} ${this.user.surname}`
      this.clearErrors(this.$validator)
      this.$vs.loading()
      const updateFields = {
        name : name,
        birthdate : this.user.birthdate,
        gender : this.user.gender,
        phone: this.user.phone
      }
      this.loginService.updateProfile(updateFields).then(
        data => {

          this.$store.dispatch('updateActivePerson', {
            birthdate : this.user.birthdate,
            gender : this.user.gender,
          })

          this.activeUser.name = name
          const person = this.activeUser.people.find(person => {
            return person.id === this.activePerson.id
          })
          person.gender = this.user.gender
          person.birthdate = this.user.birthdate

          this.$store.dispatch('updateUserInfo', {
            name: name,
            displayName: name,
            people: this.activeUser.people,
            phone: this.user.phone
          })

          this.$vs.loading.close()
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
        },
        error => {
          this.$vs.loading.close()
          this.showErrors(this.$validator, error)
          this.notifyError(this.$vs, this.$t('ocorreu-um-erro'))
        }
      )
    },
    aditionalStyleWhen(bool, main, aditional) {
      if (bool) {
        return `${main} ${aditional}`
      } else {
        return main
      }
    },
    clearEdits() {
      if (!this.rotating && !this.zooming) {
        this.avatarEditor.rotation = 0
        this.avatarEditor.scale = 1
      }
    },
    updateProfilePicture() {
      const image = this.$refs.avatareditor.getImageScaled().toDataURL()
      this.loginService.updateProfile({profile_picture : image})
      this.avatarEditor.visible = false
      this.$store.dispatch('updateUserInfo', {
        profile_picture: image
      })
      this.key++
    },
    doZoom(direction) {
      if (!this.avatarEditor.zoom.locked) {
        if (this.zooming) {
          this.avatarEditor.zoom.on = false
        } else {
          this.avatarEditor.zoom.direction = direction
          this.avatarEditor.zoom.on = true
        }
      }
    },
    tinyZoom(direction) {
      if (!this.avatarEditor.zoom.locked) {
        if (!this.zooming) {
          this.doZoomStep(0.2, direction)
        }
      }
    },
    doRotate(direction, degree) {
      if (!this.rotating) {
        this.avatarEditor.rotate.direction = direction
        this.avatarEditor.rotate.degree = degree
        this.avatarEditor.rotate.on = true
        this.avatarEditor.zoom.locked = true
      }
    },
    doRotateStep(magnitude, direction) {
      this.avatarEditor.rotation += (magnitude * direction)
      this.avatarEditor.rotationCounter++
    },
    doZoomStep(magnitude, direction) {
      this.avatarEditor.scale = this.avatarEditor.scale + (magnitude * direction)
    },
    loadUserName() {
      const nameTokens = (_.get(this.activeUser, 'displayName', '') || '').split(' ')
      const name = nameTokens.splice(0, 1)[0]
      const surname = nameTokens.join(' ')
      this.user.surname = surname
      this.user.name = name
    }
  },
  computed: {
    validDetails() {
      const phone = this.user.phone
      const name = `${this.user.name}${this.user.surname}`
      return (
        !_.isEmpty(name)
      )
    },
    activeUser() {
      return this.$store.state.AppActiveUser
    },
    activePerson() {
      return this.$store.state.activePerson
    },
    myProfilePicture() {
      return this.$store.state.AppActiveUser.profile_picture
    },
    rotating() {
      return this.avatarEditor.rotate.on
    },
    zooming() {
      return this.avatarEditor.zoom.on
    },
    zoomingIn() {
      return !!((this.zooming && this.avatarEditor.zoom.direction > 0))
    },
    zoomingOut() {
      return !!((this.zooming && this.avatarEditor.zoom.direction < 0))
    },
    rotatingCW() {
      return !!((this.rotating && this.avatarEditor.rotate.direction > 0))
    },
    rotatingCCW() {
      return !!((this.rotating && this.avatarEditor.rotate.direction < 0))
    },
  },
  watch: {
    '$store.state.AppActiveUser'(val) {
      this.key++
    },
    activePerson() {
      this.user.gender = this.activePerson.gender || null
      this.user.birthdate = this.activePerson.birthdate
    },
    activeUser() {
      this.loadUserName()
      this.user.email = this.activeUser.email
      this.user.phone = this.activeUser.phone
      this.since = this.activeUser.created_at
    },
    rotating(apply) {
      if (apply) {
        this.avatarEditor.actionTimer = setInterval(() => {
          this.doRotateStep(3, this.avatarEditor.rotate.direction)
          if (this.avatarEditor.rotationCounter >= this.avatarEditor.rotate.degree / 3) {
            this.avatarEditor.rotationCounter = 0
            this.avatarEditor.zoom.locked = false
            this.avatarEditor.rotate.on = false
          }

        }, 5)
      } else {
        clearInterval(this.avatarEditor.actionTimer)
        this.avatarEditor.actionTimer = 0
      }
    },
    zooming(apply) {
      if (apply) {
        const direction = this.avatarEditor.zoom.direction
        this.avatarEditor.actionTimegate = setTimeout(() => {
          this.avatarEditor.actionTimer = setInterval(() => {
            if ((direction > 0 && this.avatarEditor.scale < 5.0) || (direction < 0 && this.avatarEditor.scale > 1.0)) {
              this.doZoomStep(0.1, this.avatarEditor.zoom.direction)
            }
          }, 5)
        }, 200)
      } else {
        clearTimeout(this.avatarEditor.actionTimegate)
        clearInterval(this.avatarEditor.actionTimer)
        this.avatarEditor.actionTimer = 0
      }
    }
  },
  created() {
    this.$store.watch(
      (state) => {
        return this.$store.state.AppActiveUser
      },
      (newValue, oldValue) => {
        this.key++
      }
    )
  },
  beforeMount() {
    this.loginService = LoginService.build(this.$vs)
  },
  mounted() {
    this.loadUserName()
    this.user.gender = !this.isEmpty(this.activePerson.gender) ? this.activePerson.gender.trim() : null
    this.user.birthdate = this.activePerson.birthdate
    // this.user.name = this.activeUser.name
    this.user.email = this.activeUser.email
    this.since = this.activeUser.created_at
  }
}
</script>

<style>
  .vdatetime-input:disabled.vs-input--input {
    opacity: 1;
  }
  .vdatetime-input:disabled {
    background-color: #00000000;
  }
  .properSlider {
    width: 200px !important;
  }
  .tooltipZFix {
    z-index: 60000 !important;
  }
  .overlay-container{
    max-height: 100px !important;
    position: relative !important;
  }
  .overlayed{
    position: absolute !important;
  }

  /* ._profile-details .con-select {
    width: auto !important;

  } */

</style>
