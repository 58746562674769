<template>
  <div class="flex flex-col gap-2">
    <div>
      <vs-input
        :label="$t('nova-senha')"
        :placeholder="$t('digite-sua-nova-senha')"
        ref="password"
        type="password"
        v-validate="'verify_password'"
        data-vv-as="Senha"
        id="password"
        name="password"
        v-model="password"
        class="w-full"
      />
      <span class="text-danger text-sm">{{ errors.first('password') }}</span>
    </div>
    <div>
      <vs-input
        :label="$t('confirmacao-nova-senha')"
        :placeholder="$t('digite-a-senha-novamente')"
        type="password"
        v-validate="'confirmed:password'"
        data-vv-as="Confirmação Senha"
        id="password_confirmation"
        name="password_confirmation"
        v-model="password_confirmation"
        class="w-full"
      />
      <span class="text-danger text-sm">{{ errors.first('password_confirmation') }}</span>
    </div>
    <div class="pt-8">
      <vs-button
        :disabled="!validPassword"
        @click="changePassword"
        class="float-right"
      >
        {{ $t('action.save') }}
      </vs-button>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    password: '',
    password_confirmation: ''
  }),

  computed: {
    validPassword() {
      return !this.$validator.errors.any()
    }
  },

  methods: {
    changePassword() {
      this.$vs.loading()

      const payload = {
        userDetails: {
          password: this.password,
          passwordConfirm: this.password_confirmation
        },
        notify: this.$vs.notify,
        closeAnimation: this.$vs.loading.close,
        acl: this.$acl,
        flags: {
          nohome: true
        }
      }
      this.$store.dispatch('auth/changePasswordAttempt', payload).finally(
        () => {
          this.password = ''
          this.password_confirmation = ''
        }
      )
    }
  }
}
</script>

<style>

</style>