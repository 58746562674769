<template>
  <div class="_profile">
    <vs-tabs :position="isSmallScreen(500) ? 'top' : 'left'">
      <vs-tab :class="{'pt-0': !isSmallScreen(500)}" :label="$t('general')">
        <profile-details/>
      </vs-tab>
      <vs-tab :class="{'pt-0': !isSmallScreen(500)}" :label="$t('password')">
        <vx-card>
          <profile-password/>
        </vx-card>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import ProfileDetails from './profile/ProfileDetails.vue'
import ProfilePassword from './profile/ProfilePassword.vue'

export default {
  components: { ProfileDetails, ProfilePassword },
  data: () => ({
  }),
  methods: {
  },
  computed: {
  },
  watch: {
  },
  beforeMount() {
  },
  mounted() {
  }
}
</script>

<style>
  ._profile .ul-tabs.vs-tabs--ul.ul-tabs-left {
    min-width: 15rem;
  }
  
</style>