var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col gap-2" }, [
    _c(
      "div",
      [
        _c("vs-input", {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: "verify_password",
              expression: "'verify_password'",
            },
          ],
          ref: "password",
          staticClass: "w-full",
          attrs: {
            label: _vm.$t("nova-senha"),
            placeholder: _vm.$t("digite-sua-nova-senha"),
            type: "password",
            "data-vv-as": "Senha",
            id: "password",
            name: "password",
          },
          model: {
            value: _vm.password,
            callback: function ($$v) {
              _vm.password = $$v
            },
            expression: "password",
          },
        }),
        _c("span", { staticClass: "text-danger text-sm" }, [
          _vm._v(_vm._s(_vm.errors.first("password"))),
        ]),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("vs-input", {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: "confirmed:password",
              expression: "'confirmed:password'",
            },
          ],
          staticClass: "w-full",
          attrs: {
            label: _vm.$t("confirmacao-nova-senha"),
            placeholder: _vm.$t("digite-a-senha-novamente"),
            type: "password",
            "data-vv-as": "Confirmação Senha",
            id: "password_confirmation",
            name: "password_confirmation",
          },
          model: {
            value: _vm.password_confirmation,
            callback: function ($$v) {
              _vm.password_confirmation = $$v
            },
            expression: "password_confirmation",
          },
        }),
        _c("span", { staticClass: "text-danger text-sm" }, [
          _vm._v(_vm._s(_vm.errors.first("password_confirmation"))),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "pt-8" },
      [
        _c(
          "vs-button",
          {
            staticClass: "float-right",
            attrs: { disabled: !_vm.validPassword },
            on: { click: _vm.changePassword },
          },
          [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }